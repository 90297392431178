import { useEffect, useState } from 'react'
import { KTIcon } from '../../../../../_metronic/helpers'
import { IPackages } from '../../../../../app/modules/subscriptions/create/ISubscription'
import { Packages } from '../../../../../app/modules/subscriptions/context/SubscriptionContext'
import { NumbertoWord } from '../../../../helpers/UtilityHelpers'

interface IMyProps {
  packages: Packages
  packageList1: IPackages[]
  handleSelectPackage: (updatedPkg: object) => void
  currentPackage: any
}

const Step1: React.FC<IMyProps> = (props: IMyProps) => {

  const { packages: newPackage, handleSelectPackage } = props
  const [selectedOption, setSelectedOption] = useState('')

  const handleReset = () => {
    setSelectedOption('')
  }

  const handlePackageType = (packageItem: any, id: any) => {
    let typeObj = {
      ...newPackage,
      packageType: packageItem.name,
      packageId: id,
      planType: '',
    }
    handleSelectPackage(typeObj)
    handleReset()
  }

  const handlePackagePlan = (duration: any, optionValue: any) => {
    handleSelectPackage({
      ...newPackage,
      planType: duration.months,
      planCost: 0,
      total_KMs: duration.total_KMs,
      contracted_services: duration.contracted_services,
      tyres: duration.tyres,
    })
    setSelectedOption(optionValue)
  }

  useEffect(() => {
    // Select default duration when `packageId` changes
    if (newPackage.packageId) {
      const defaultDuration = props.packageList1.find(
        (pkg) => pkg._id === newPackage.packageId
      )?.durations.find((d: any) => d.months === 0)

      if (defaultDuration) {
        handlePackagePlan(defaultDuration, 'defaultOption')
      }
    }
  }, [newPackage.packageId]) // Re-run effect when packageId changes

  const formatDuration = (months: number) => {
    if (months < 1) {
      const days = Math.round(months * 30) // Approx for 1 month = 30 days
      return `${days} Days Plan`
    } else if (months >= 12) {
      const years = months / 12
      return `${NumbertoWord(years)} Years Plan`
    } else {
      return `${months} Months Plan`
    }
  }

  // Filter the package list if the first package name is "Rent To Own"
  const filteredPackages =
    props.currentPackage?.[0]?.package_name === 'Rent To Own'
      ? props.packageList1.filter((pkg) => pkg.name === 'Rent To Own')
      : props.packageList1;

      console.log(newPackage);
      
  return (
    <div className='current' data-kt-stepper-element='content'>
      <div className='w-100'>
        <div className='fv-row'>
          <label className='d-flex align-items-center fs-5 fw-semibold mb-4'>
            <span className='required'>Package Types</span>
            <i
              className='fas fa-exclamation-circle ms-2 fs-7'
              data-bs-toggle='tooltip'
              title='Select your package category'
            ></i>
          </label>

          <div id='accordionExample'>
            {filteredPackages &&
              filteredPackages.map((packageData, i) => (
                <div key={i}>
                  <label className='d-flex align-items-center justify-content-between mt-6 cursor-pointer accordion-item'>
                    <span className='d-flex align-items-center me-2'>
                      <span className='symbol symbol-50px me-6'>
                        <span className='symbol-label bg-light-primary'>
                          <KTIcon iconName='package' className='fs-1 text-primary' />
                        </span>
                      </span>
                      <span className='d-flex flex-column'>
                        <span className='fw-bolder fs-5'>{packageData?.name}</span>
                      </span>
                    </span>

                    <span
                      className='form-check form-check-custom form-check-solid'
                      id={`headingOne${i}`}
                    >
                      <input
                        className='form-check-input'
                        data-bs-toggle='collapse'
                        data-bs-target={`#collapseOne${i}`}
                        aria-controls={`collapseOne${i}`}
                        type='radio'
                        checked={newPackage?.packageId === packageData?._id}
                        name='package_duration'
                        onChange={() => handlePackageType(packageData, packageData?._id)}
                      />
                    </span>
                  </label>

                  {packageData?.durations?.length > 0 && (
                    <>
                      {packageData?.durations
                        .filter((duration: any) => duration.months !== 0)
                        .map((duration: any, ix: number) => (
                          <div
                            id={`collapseOne${i}`}
                            className='accordion-collapse collapse ps-15 ms-6'
                            aria-labelledby={`headingOne${i}`}
                            data-bs-parent='#accordionExample'
                            key={ix}
                          >
                            <div className='py-1 accordion-body'>
                              <div className='d-flex flex-stack flex-wrap'>
                                <div
                                  className='d-flex align-items-center collapsible toggle collapsed'
                                  data-bs-toggle='collapse'
                                  data-bs-target={`#kt_packages${i}`}
                                >
                                  <div className='me-3'>
                                    <div className='d-flex align-items-center fw-bold'>
                                      {/* {duration.months >= 12
                                        ? `${NumbertoWord(duration.months / 12)} Years Plan`
                                        : `${duration.months} Months Plan`} */}
                                      {formatDuration(duration.months)}
                                    </div>
                                  </div>
                                </div>
                                <div className='d-flex my-3 ms-9'>
                                  <label className='form-check form-check-custom form-check-solid'>
                                    <input
                                      className='form-check-input'
                                      type='radio'
                                      name={`duration_${i}`} // Make radio unique to avoid conflicts
                                      value={`option${ix}`}
                                      checked={selectedOption === `option${ix}`}
                                      onChange={() =>
                                        handlePackagePlan(duration, `option${ix}`)
                                      }
                                    />
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                    </>
                  )}
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export { Step1 }
