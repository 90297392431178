import axios, {AxiosResponse} from 'axios'
import {ID, Response} from '../../../../../../_metronic/helpers'
import {Category, UsersQueryResponse} from './_models'

const API_URL = process.env.REACT_APP_THEME_API_URL
const BACKEND_API_URL = process.env.REACT_APP_BACKEND_API_URL
const USER_URL = `${API_URL}/user`

const GET_CATEGORY_LIST = `${BACKEND_API_URL}/categories/get-all`
const ADD_NEW_CATEGORY = `${BACKEND_API_URL}/categories/create`
const EDIT_CATEGORY = `${BACKEND_API_URL}/categories/edit`
const EDIT_MULTI_CATEGORY = `${BACKEND_API_URL}/categories/update-multiple`
const ADD_EXTRA_PACKAGE = `${BACKEND_API_URL}/categories/add-extra-packages`
const DELETE_CATEGORY = `${BACKEND_API_URL}/categories/delete`
const GET_CAT_BY_ID = `${BACKEND_API_URL}/categories/get-cat-id`

const getCategories = async (query: string): Promise<UsersQueryResponse> => {
  const d = await axios.get(`${GET_CATEGORY_LIST}?${query}`)
  return d.data
}

const createCategory = (cat: Category): Promise<Category | undefined> => {
  return axios
    .post(`${ADD_NEW_CATEGORY}`, cat)
    .then((response: AxiosResponse<Category>) => response.data)
    .catch((error) => {
      console.error('API error:', error)
      throw error
    })
}

const updateCategory = (cat: Category): Promise<Category | undefined> => {
  return axios
    .put(`${EDIT_CATEGORY}?id=${cat._id}`, cat)
    .then((response: AxiosResponse<Category>) => response.data)
    .catch((error) => {
      console.error('API error:', error)
      throw error
    })
}

const updateMultipleCategory = (data: any): Promise<any | undefined> => {
  return axios
    .put(`${EDIT_MULTI_CATEGORY}`, data)
    .then((response: AxiosResponse<any>) => response.data)
    .catch((error) => {
      console.error('API error:', error)
      throw error
    })
}

const addExtraPackage = (data: any): Promise<any | undefined> => {
  return axios
    .post(`${ADD_EXTRA_PACKAGE}`, data)
    .then((response: AxiosResponse<any>) => response.data)
    .catch((error) => {
      console.error('API error:', error)
      throw error
    })
}

const getCategoryById = (id: ID): Promise<Category | undefined> => {
  return axios
    .get(`${GET_CAT_BY_ID}?id=${id}`)
    .then((response: AxiosResponse<Response<Category>>) => response.data)
    .then((response: Response<Category>) => response.data)
}

const deleteCategory = (catId: ID): Promise<void> => {
  return axios.delete(`${DELETE_CATEGORY}?id=${catId}`).then(() => {})
}

const deleteSelectedCategory = (catIds: Array<ID>): Promise<void> => {
  const requests = catIds.map((id) => axios.delete(`${USER_URL}/${id}`))
  return axios.all(requests).then(() => {})
}

export {
  getCategories,
  deleteCategory,
  deleteSelectedCategory,
  getCategoryById,
  createCategory,
  updateCategory,
  updateMultipleCategory,
  addExtraPackage,
}
